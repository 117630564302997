// import { useRef, useState } from 'react';
import { Container, Row, Col, Button, Image, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import authStyles from './auth.module.css';

const AuthFullBody = () => {

    
  return (
    <Container fluid className={authStyles.authFullBodyContainer}>

    <Navbar className={`${authStyles.authFullHeader}`} expand="lg" fixed="top" collapseOnSelect>
      <Container fluid>
        <Row className="w-100 align-items-center">
          <Col xs="6" lg="auto" className="d-flex justify-content-start">
            <Navbar.Brand href="/" className="p-0 m-0">
              <img src="/Content/Images/Svgs/header_logo.svg" alt="Logo" className={authStyles.authMainLogo} />
            </Navbar.Brand>
          </Col>
          <Col xs="6" className={`d-flex justify-content-end d-block d-lg-none`}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className={authStyles.responsiveNavbarTooggle} />
          </Col>
          <Col lg="auto" className="d-none d-lg-flex justify-content-end ms-auto">
            <Nav className={`ms-auto ${authStyles.HeaderNavLinks}`}>
              <Link to="/info/about-us">
                <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                  About Us
                </Button>
              </Link>
              <Link to="/signup/mandatory-details">
                <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                  Sign Up
                </Button>
              </Link>
              <Link to="/signin/form">
                <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                  Sign In
                </Button>
              </Link>
            </Nav>
          </Col>
        </Row>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`ms-auto d-lg-none ${authStyles.HeaderNavLinks}`}>
            <Link to="/info/about-us">
                <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                  About Us
                </Button>
              </Link>
            <Link to="/signup/mandatory-details">
              <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                Sign Up
              </Button>
            </Link>
            <Link to="/signin/form">
              <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                Sign In
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

      <Row className={`${authStyles.AuthBannerSetup}`}>
        <Row className={`${authStyles.authMainBaseRow}`}>
          <Col xs={12} md={5} className="mainPad">
            <Row className={authStyles.ContentRow}>
              <h2 className={`mb-3 ${authStyles.authBiglabel}`}>To <span className={authStyles.authHapifyrYellowLabel}>IGNITE HAPPINESS</span> in others you must first find what makes you happy</h2>
              <p className={`mb-5 ${authStyles.authContentLabel}`}>
                <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> is a creative platform and a thriving community, where people connect and find joy through shared creative workshops.
              </p>
            </Row>
            <Row className={`d-flex ResponsiveCenteredAlignedButtons`}>
              <Col xs="auto">
                <Link to="/signup/mandatory-details">
                  <Button className={authStyles.authMainButtons}>Join Hapifyr</Button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={7}>
              <img src="/Content/Images/Auth/authMain.PNG" alt="Banner" className={authStyles.authMainBanner} />
          </Col>
        </Row>
        <hr className={`${authStyles.mainRowHRsNone}`} />

        <Row>
          <Col xs={12} md={6} lg={6} className={`mainPad`}>
            <div className={authStyles.authMainBanner2Row}>
              <img src="/Content/Images/Auth/authMain2.PNG" alt="Banner" className={authStyles.authMainBanner2} />
            </div>
          </Col>
          <Col xs={12} md={5} lg={5} className={authStyles.authmain2Pad}>
            <Row className={authStyles.ContentRow}>
              <h2 className={`${authStyles.authBiglabelSpace5} ${authStyles.authBig2label}`}>Ignite Your Creativity, Find Your <span className={authStyles.authHapifyrGrayLabel}>Community</span></h2>
              <p className={`${authStyles.authContent2Label}`}>
                In a fast-moving world, finding time to indulge your creativity can be a challenge.
                That's why we created <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>; a welcoming, 
                inclusive place where people from all walks of life can come together through their hobbies, from anywhere, anytime.
                <br /><br />
                At <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> you'll find:
                <br />
              </p>
                <div className={`${authStyles.authContent2Label}`}>
                    <ul>
                      <li>happiness is contagious, </li>
                      <li>you can enjoy your passions, explore new ones, and </li>
                      <li>boast about it (it's encouraged!) </li>
                    </ul>
                </div>   
             
            </Row>
          </Col>
          <Col xs={0} md={1} lg={1}></Col>
        </Row>
        <hr className={`${authStyles.mainRowHRsNone}`} />

        <Row className={`mainPad`}>
            <Row className={authStyles.ContentRow}>
              <Col xs={12} md={4} lg={4} className={authStyles.authMiddleContentRow}>
                <div className={authStyles.authBanner3ImgDiv}>
                  <Image src="/Content/Images/Auth/Auth2/mini-3-1.jpg" alt="Banner3-1" 
                  roundedCircle
                  className={authStyles.authMiniBanner31} />
                </div>
                <h2 className={`mb-3 ${authStyles.authBigLabel3}`}>Your Community</h2>
                <p className={`mb-5 ${authStyles.authContentLabel3}`}>
                  In your community you’ll find a warm embrace of diverse individuals brought together through their shared passions. Here, friendships blossom, and you feel that you belong.             
                </p>
              </Col>

              <Col xs={12} md={4} lg={4} className={authStyles.authMiddleContentRow}>
                <div className={authStyles.authBanner3ImgDiv}>
                  <Image src="/Content/Images/Auth/Auth2/mini-3-2.png" alt="Banner3-2" 
                  roundedCircle
                  className={authStyles.authMiniBanner31} />
                </div>
                <h2 className={`mb-3 ${authStyles.authBigLabel3}`}>Your Workshops</h2>
                <p className={`mb-5 ${authStyles.authContentLabel3}`}>
                  The <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> community offer interactive workshops to ignite your creative passions, in art, cooking, music, and more. Be guided by friendly expertise and infectious enthusiasm.
                </p>
              </Col>

              <Col xs={12} md={4} lg={4} className={authStyles.authMiddleContentRow}>
                <div className={authStyles.authBanner3ImgDiv}>
                  <Image src="/Content/Images/Auth/Auth2/mini-3-3.jpg" alt="Banner3-3" 
                  roundedCircle
                  className={authStyles.authMiniBanner31}
                  />
                </div>
                <h2 className={`mb-3 ${authStyles.authBigLabel3}`}>Your Connections</h2>
                <p className={`mb-5 ${authStyles.authContentLabel3}`}>
                  Shared experiences foster deeper connections; so invite your friends, and enjoy a workshop together in a private watch party setting where you can create with your people.  
                </p>
              </Col>
            </Row>
            
            <Row className={`text-center align-items-center justify-content-between ResponsiveCenteredAlignedButtons`}>
                <Link to="/signup/mandatory-details">
                  <Button className={authStyles.authMainButtons}>Join Hapifyr</Button>
                </Link>
            </Row>
        </Row>
        <hr className={`${authStyles.mainRowHRsNone}`} />

        <Row>
          <div className={`mb-5 mainPad`}>
            <h2 className={`${authStyles.authBig2label}`}>Join the <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> community</h2>
          </div>
          <Col xs={12} md={6} lg={6} className={`mainPad`}>
            <Row className={`align-items-center justify-content-start`}>
              <Col xs={6} md={6} lg={6} className={`text-end`}>
                <img src="/Content/Images/Auth/Auth2/mini-4-1.jpg" alt="Banner4-1" className={authStyles.authMiniBanner4_1Image} />
                <img src="/Content/Images/Auth/Auth2/mini-4-2.jpg" alt="Banner4-2" className={authStyles.authMiniBanner4_1Image} />
              </Col>
              <Col xs={6} md={6} lg={6} className={`text-start`}>
                <img src="/Content/Images/Auth/Auth2/mini-4-3.jpeg" alt="Banner4-3" className={authStyles.authMiniBanner4_2Image} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={5} lg={5} className={authStyles.authmain2Pad}>
            <Row className="d-flex">
                <label className={`${authStyles.authBigLabel4}`}>Get started </label>
                <p className={`${authStyles.authContentLabel4}`} >Join <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>, and personalise your profile. Your profile is your canvas, let it reflect who you are.</p>
            </Row>

            <Row className="d-flex">
                <label className={`${authStyles.authBigLabel4}`}>Explore creations </label>
                <p className={`${authStyles.authContentLabel4}`}>Once inside, explore workshops created by our hosts and <span className={authStyles.authHapifyrGrayLabel}>'boasts'</span> from our community about the workshops they've enjoyed. When you find something you like, select it, buy it and watch it, all in the <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> platform. </p>
            </Row>

            <Row className="d-flex">
                <label className={`${authStyles.authBigLabel4}`}>Celebrate with a <span className={authStyles.authHapifyrGrayLabel}>'boast'</span> </label>
                <p className={`${authStyles.authContentLabel4}`}>Our community love to see photos of others' creations. At the end of a workshop, take a photo of your creation and review the workshop. Your <span className={authStyles.authHapifyrGrayLabel}>'boast'</span> appears in the feed for others to enjoy.</p>
            </Row>

          </Col>
          <Col xs={0} md={1} lg={1}></Col>
        </Row>
        <hr className={`${authStyles.mainRowHRsNone}`} />

        <Row className={`mainPad`}>
          <Col xs={12} md={5} lg={5}>
            <Row className={authStyles.ContentRow}>
              <h2 className={`${authStyles.authBiglabelSpace5} ${authStyles.authBig2label}`}>Become a host</h2>
              <p className={`mb-2 ${authStyles.authContent2Label}`}>
                Our hosts are everyday people with a creative process to share. 
                <br />
                Our step-by-step tool makes it easy to upload a pre-recorded video to create an online workshop. Upload one video, or piece together a sequence of shorter videos, and publish it in the online store. 
                <br />
                Workshops for groups and other features will follow in later releases.
                <br />
              </p>
            </Row>
          </Col>
          <Col xs={0} md={1} lg={1}></Col>
          <Col xs={12} md={6} lg={6} className={`${authStyles.authmain2Pad} ${authStyles.responsiveAuthBanner5Align}`}>
              <img src="/Content/Images/Auth/Auth2/mini-4DD.jpeg" alt="Banner4" className={authStyles.authMainBanner4DD} />
          </Col>
        </Row>
        <hr className={`${authStyles.mainRowHRsNone}`} />

        <Row>
          <Col xs={12} md={6} lg={6} className={`mainPad ${authStyles.responsiveAuthBanner5Align}`}>
              <img src="/Content/Images/Auth/Auth2/Collage5.jpg" alt="Banner5" className={authStyles.authMainBanner5} />
          </Col>
          <Col xs={12} md={5} lg={5} className={authStyles.authmain2Pad}>
            <Row className={authStyles.ContentRow}>
              <h2 className={`${authStyles.authBiglabelSpace5} ${authStyles.authBig2label}`}>Invite your <span className={authStyles.authHapifyrGrayLabel}>community</span>,  get rewarded</h2>
              <p className={`mb-2 ${authStyles.authContent2Label}`}>
                Once you've joined <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>, explore our community referral program where you'll find details
                of how the referral rewards work, including a unique link so you can invite your network, encouraging others to ignite happiness
                at <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>. 
              </p>
            </Row>
          </Col>
          <Col xs={0} md={1} lg={1}></Col>
        </Row>


        <hr />
        <Row className={`mainPad`}>
            <Col xs={12} md={6} lg={6} className={`minPadding`}>
              <p className={`mb-4 ${authStyles.authContentTextLabel7}`} >Welcome to <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>
              <br />
              where happiness sparks connections</p>
            </Col>
            <Col xs={12} md={6} lg={6} className={`minPadding text-center`}>
              <Link to="/signup/mandatory-details">
                <Button className={authStyles.authMainButtons}>Join Hapifyr</Button>
              </Link>
            </Col>
        </Row>

      </Row>
    
    </Container>
    
  );
};

export default AuthFullBody;
